/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    /*color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;*/
    display: inline-block;
    cursor: pointer;
    /*border-radius: 3px;*/ }
    .owl-theme .owl-nav [class*='owl-']:hover {
      /*background: #869791;
      color: #FFF;*/
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 8px;
      background: #eae7de;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; -webkit-transition: all 0.5s ease 0s; -moz-transition: all 0.5s ease 0s; -ms-transition: all 0.5s ease 0s; -o-transition: all 0.5s ease 0s; transition: all 0.5s ease 0s;}
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #c7a17a; width: 35px; }
