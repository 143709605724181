/* line 1, ../sass/simplelightbox.scss */
.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.7;
  display: none;
  z-index: 1006;
}

/* line 12, ../sass/simplelightbox.scss */
.sl-wrapper {
  z-index: 1000;
}
/* line 14, ../sass/simplelightbox.scss */
.sl-wrapper button {
  border: 0 none;
  background: transparent;
  font-size: 24px;
  padding: 0;
  cursor: pointer;
}
/* line 20, ../sass/simplelightbox.scss */
.sl-wrapper button:hover {
  opacity: 0.7;
}
/* line 25, ../sass/simplelightbox.scss */
.sl-wrapper .sl-close {
  display: none;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 1015;
}
/* line 33, ../sass/simplelightbox.scss */
.sl-wrapper .sl-counter {
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1015;
}
/* line 41, ../sass/simplelightbox.scss */
.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}
/* line 44, ../sass/simplelightbox.scss */
.sl-wrapper .sl-navigation button {
  position: fixed;
  top: 50%;
  margin-top: -10px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-algin: center;
  display: block;
  z-index: 1015;
}
/* line 54, ../sass/simplelightbox.scss */
.sl-wrapper .sl-navigation button.sl-next {
  right: 40px;
}
/* line 58, ../sass/simplelightbox.scss */
.sl-wrapper .sl-navigation button.sl-prev {
  left: 40px;
}
/* line 64, ../sass/simplelightbox.scss */
.sl-wrapper .sl-image {
  position: fixed;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 10000;
}
/* line 69, ../sass/simplelightbox.scss */
.sl-wrapper .sl-image img {
  margin: 0;
  padding: 0;
  display: block;
}
/* line 74, ../sass/simplelightbox.scss */
.sl-wrapper .sl-image .sl-caption {
  display: none;
  padding: 10px;
  color: #fff;
  background: #000;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
/* line 86, ../sass/simplelightbox.scss */
.sl-wrapper .sl-image .sl-download {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #fff;
  z-index: 1005;
}

/* line 97, ../sass/simplelightbox.scss */
.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 1007;
  -webkit-animation: pulsate 1s ease-out infinite;
  -moz-animation: pulsate 1s ease-out infinite;
  -ms-animation: pulsate 1s ease-out infinite;
  -o-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-moz-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-o-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-ms-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
