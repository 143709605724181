@import '../../Despina/css/icon-plugin/font-awesome';
@import '../../Despina/css/icon-plugin/fontello';
@import '../../Despina/css/revolution-plugin/extralayers';
@import '../../Despina/css/revolution-plugin/settings';
@import '../../Despina/css/bootstrap-plugin/bootstrap';
@import '../../Despina/css/bootstrap-plugin/bootstrap-slider';
@import '../../Despina/css/animation/animate.min';
@import '../../Despina/css/owl-carousel/owl.carousel';
@import '../../Despina/css/owl-carousel/owl.theme.default';
@import '../../Despina/css/light-box/simplelightbox';
@import '../../Despina/css/light-box/magnific-popup';
@import '../../Despina/css/scroll-bar/jquery.mCustomScrollbar';
@import '../../Despina/css/form-field/jquery.formstyler';
@import '../../Despina/css/Slick-slider/slick-theme';
@import '../../Despina/css/theme';
@import '../../Despina/css/responsive';
@import url('https://fonts.googleapis.com/css?family=Barlow&display=swap');
$roze: #B8174D;
a {
    color: $roze;
    &:hover {
        color: #E1AF00;
    }
}
body, h1, h2, h3, h4, h5, h6, p, li {
	font-family: 'Barlow', sans-serif;
}
body, p, li {
    font-size: 16px;
    color: #414141;
}
.text-turkish {
    color: #414141;
}
.text-coffee {
    color: $roze;
}
h1, h2, h3, h4, h5, h6 {
	color: $roze;
}
.service-main-partition h1, .service-main-partition h2, .service-main-partition h3, .service-main-partition h4, .service-main-partition h5, .service-main-partition h6 {
    color: $roze;
}
.blog-over-info, .discover-menu {
	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
}
.popular-tag button{
	border: none;
	borx-shadow: none;
    display: inline-block;
    width: auto;
    padding: 0 20px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    color: #7a7676;
    min-width: 88px;
    background: #ffffff;
    text-align: center;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    margin: 0 5px 15px;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #7a7676;
}
.popular-tag button:focus {
	outline:0;
}
.popular-tag button:hover {
    background: #20202f;
    color: #ffffff;
}
.popular-tag button.is-checked {
    background: #2B2B61;
    color: #ffffff;
    &:hover{
	    background: #2B2B61;
    	color: #ffffff;
	    cursor: default;
	}
}
.copyright {
	p {
		color: #E1AF00;
	}
	a {
		color: #E1AF00;
		text-decoration: none;
		&:hover {
			color: #E1AF00;
			text-decoration: underline;
		}
	}
}
.footer-part {
	background-color:  #2B2B61;
	.footer-social li a {
	    color: #E1AF00;
	    &:hover {
	    	color: #fff;
	    }
	}
    body, p, li {
        color: #fff;
    }
}
.button-dark-red {
    background: #2B2B61;
    color: #ffffff;
    border-color: #2B2B61;
}
.menu-nav-main {
    background: #2B2B61;
}
@media (max-width: 767px) {
    .menu-icon {
        a {
            top: -3px;
        }
    }
    .menu-top-part {
        margin-top: -41px;
    }
    .category-icon>ul {
        left: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 996;
        background: none;
        display: block;
        li {
            width: auto;
            padding-right: 0;
            a {
                margin: 0;
            }
        }
        li:first-child a {
            margin-top: 0;
        }
        li:last-child a {
            margin-bottom: 0;
        }
    }
}
ul.icon_menu {
    text-align: center;
    strong {
        display: none;
    }
    li {
        display: inline-block;
    }
    @media (min-width: 1200px) {
        strong {
            display: table-cell;
        }
    }
}
th {
    padding: 0;
    border-bottom: 2px solid #959393;
    padding: 10px 15px;
}
td {
    padding: 0;
    border-bottom: 2px solid #f4f2ed;
    padding: 10px 15px;
}
.button-dark-red:hover {
    background: #E1AF00;
    border-color: #E1AF00;
}
.set_padding_top_80 {
	padding-top: 80px !important;
}
.set_padding_top_40 {
	padding-top: 40px !important;
}
.set_padding_top_20 {
	padding-top: 20px !important;
}
.set_padding_top_0 {
	padding-top: 0px !important;
}
.set_padding_bottom_80 {
	padding-bottom: 80px !important;
}
.set_padding_bottom_40 {
	padding-bottom: 40px !important;
}
.set_padding_bottom_20 {
	padding-bottom: 20px !important;
}
.set_padding_bottom_0 {
	padding-bottom: 0px !important;
}
.copyright {
    background: darken(#2B2B61, 10%);
}
.category-icon li {
    padding-right: 4.5%;
}
.mt-2 {
    margin-top: 2rem;
}
.mb-4 {
    margin-bottom: 4rem;
}
.icon_ijsenchocolade {
	width: 60px;
    height: 60px;
}
@media (min-width: 1200px) {
.icon_ijsenchocolade {
	width: 40px;
    height: 40px;
}
}
.icon_ijskarren {
    background: url(../../../../uploads/ijsenchocolade/icons/icon_ijskarren.png) no-repeat center center;
}
.icon_cadeaubonnen {
    background: url(../../../../uploads/ijsenchocolade/icons/icon_cadeaubonnen.png) no-repeat center center;
}
.icon_chocoladeletters {
    background: url(../../../../uploads/ijsenchocolade/icons/icon_chocoladeletters.png) no-repeat center center;
}
.icon_ijstaarten {
    background: url(../../../../uploads/ijsenchocolade/icons/icon_ijstaarten.png) no-repeat center center;
}
.icon_relatiegeschenken {
    background: url(../../../../uploads/ijsenchocolade/icons/icon_relatiegeschenken.png) no-repeat center center;
}
.service-list {
    height: 185px;
    padding: 58px 25px 55px 25px;
    h3 {
        font-size: 26px;
    }
}
.service-list-back {
    padding: 58px 25px 20px 25px;
}
.margin_top_30 {
    margin-top: 30px;
}
.blog_content_section {
    ul {
        margin: 0 0 2rem 5px;
        list-style: disc;
        li {
            margin: 0 0 0 10px;
            list-style: disc;
        }
    }
}
@media (max-width: 560px) {
    .header-nav-inside {
        .logo {
            img {
                max-width: 150px;
            }
        }
    }
}
.feature-info {
    .plain-btn {
        margin-bottom: 10px;
    }
}
.text-dark {
    color: #2B2B61;
}
.date-feature-solo {
    position: relative;
    margin: 0 0 2rem 2rem;
}
.lead {
    font-weight: bold;
}
.mfp-title {
    a {
        color: #fff;
        text-decoration: underline;
        &:hover {
            color: $roze;
        }
    }
}
.blog-over-info .button-default {
    margin-bottom: 78px;
}
.content_blocks_content  {
    ul {
        margin-left: 25px;
        margin-bottom: 25px;
        li {
            padding-left: 25px;
            position: relative;
            &:before {
                content: "•";
                position: absolute;
                left: 0px;
                top: 0px;
                font-size: 18px;
                color: rgb(228, 185, 91);
            }
        }
    }
}
.facebook_item {
    .gallery-overlay-inner {
        background: #4267b2;
    }
}
.feature-info a {
    color: $roze;
}